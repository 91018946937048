<template>
  <div :style="styleObject">
    <div class="row">
      <div class="col-12">
        <table class="table">
          <thead>
            <tr>
              <th>JR #</th>
              <th>Type</th>
              <th>Site</th>
              <th>Completed By</th>
              <th>Completed At</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="job in recentJobs" :key="job.id">
              <td>{{job.id}}</td>
              <td>{{job.type}}</td>
              <td>{{job.site_name}}</td>
              <td>{{job.completed_user.fname}} {{job.completed_user.lname}}</td>
              <td><Time v-if="job.completed_at" :datetime="job.completed_at" format="dd/MM/yyyy HH:mm"></Time></td>
              <td>
                <button v-if="$hasRole('Support')" type="button" class="btn btn-sm btn-light" @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})">View Job</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Time from '../../components/Time';
import {DateTime} from 'luxon';

export default {
  name: "MonitorCompletedJobsWidget",
  components: {Time},
  props: {
    config: Object,
    socket: Object
  },
  data(){
    return {
      recentJobs: [],
    }
  },
  mounted(){
    this.socket.on('completedjob', this.handleCompletedJobs);
    this.socket.on('authenticated', () => {
      this.socket.emit('join-channel', {channel: 'jobs', user_id: 'MONITOR_BOARD'});
    });
    if(this.socket.authenticated){
      this.socket.emit('join-channel', {channel: 'jobs', user_id: 'MONITOR_BOARD'});
    }else{
      setTimeout(() => {
        this.socket.emit('join-channel', {channel: 'jobs', user_id: 'MONITOR_BOARD'});
      }, 5000);
    }
    this.loadRecentJobs();
    setTimeout(() => {
      this.$router.go(0);
    }, 1000 * 60 * 60 * 2);
    console.log("Done!");
  },
  methods: {
    loadRecentJobs(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/dashboard/recentjobs`)
          .then(response => {
            if(response.data.success){
              this.recentJobs = response.data.jobs
            }
          })
          .catch(error => {
            this.$error("Failed to load recent jobs", error);
          })
    },
    handleCompletedJobs(){
      this.loadRecentJobs();
      // let timerInterval
      // Swal.fire({
      //   icon: 'success',
      //   title: 'Job Completed!',
      //   html: 'A job has just been completed!',
      //   timer: 2000,
      //   timerProgressBar: true,
      //   didOpen: () => {
      //     Swal.showLoading()
      //     const b = Swal.getHtmlContainer().querySelector('b')
      //     timerInterval = setInterval(() => {
      //       b.textContent = Swal.getTimerLeft()
      //     }, 100)
      //   },
      //   willClose: () => {
      //     clearInterval(timerInterval)
      //   }
      // });
    }
  },
  computed: {
    fontSize: function(){
      if(this.config.fontSize !== null && this.config.fontSize !== undefined){
        return this.config.fontSize + 'px';
      }
      return 24 + 'px';
    },
    fontWeight: function(){
      if(this.config.fontWeight !== null && this.config.fontWeight !== undefined){
        return this.config.fontWeight;
      }
      return 'bold';
    },
    styleObject: function(){
      return {
        fontSize: this.fontSize,
        fontWeight: this.fontWeight,
        position: 'absolute',
        left: this.config.x + 'px',
        top: this.config.y + 'px',
        width: this.config.width + 'px',
        height: this.config.height + 'px',
        padding: '10px'
      }
    },
    now: function(){
      return DateTime.local().toSeconds();
    }
  }
}
</script>

<style scoped>
  .flashgreen {
    background-color: green;
  }
</style>