<template>
  <div :style="styleObject" class="card bg-dark">
    <img :src="config.src" style="object-fit: contain;">
  </div>
</template>

<script>
export default {
  name: "MonitorSimpleImage",
  props: {
    config: Object
  },
  computed: {
    styleObject: function () {
      return {
        position: 'absolute',
        left: this.config.x + 'px',
        top: this.config.y + 'px',
        width: this.config.width + 'px',
        height: this.config.height + 'px',
        padding: '10px',
        overflow: 'hidden'
      }
    }
  }
}
</script>

<style scoped>

</style>