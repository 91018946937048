<template>
  <div :style="styleObject" class="card bg-dark">{{config?.text}}</div>
</template>

<script>
export default {
  name: "MonitorTextWidget",
  props: {
    config: Object
  },
  computed: {
    fontSize: function(){
      if(this.config.fontSize !== null && this.config.fontSize !== undefined){
        return this.config.fontSize + 'px';
      }
      return 24 + 'px';
    },
    fontWeight: function(){
      if(this.config.fontWeight !== null && this.config.fontWeight !== undefined){
        return this.config.fontWeight;
      }
      return 'bold';
    },
    styleObject: function(){
      return {
        fontSize: this.fontSize,
        fontWeight: this.fontWeight,
        position: 'absolute',
        left: this.config.x + 'px',
        top: this.config.y + 'px',
        width: this.config.width + 'px',
        height: this.config.height + 'px',
        padding: '10px',
        textAlign: 'center'
      }
    }
  }
}
</script>

<style scoped>

</style>