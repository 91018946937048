<template>
  <div :style="styleObject" style="overflow-y: hidden; overflow-x: hidden;">
    <div class="row">
<!--      <div v-for="site in sites" :key="'fs-' + site.id" :class="'card bg-transparent col-' + colSize">-->
<!--        <div class="card-body bg-light-danger">-->
<!--          <div class="d-flex align-items-center">-->
<!--            <secure-image v-if="site.logo" :id="site.name + '-logo'" :endpoint="`/v1/client/${site.client_id}/logo`" width="90" height="90"></secure-image>-->
<!--&lt;!&ndash;            <img src="/assets/images/avatars/avatar-1.png" class="rounded-circle p-1 border" width="90" height="90" alt="...">&ndash;&gt;-->
<!--            <div class="flex-grow-1 ms-3">-->
<!--              <h5 class="mt-0">({{site.client_name}}) {{site.name}}</h5>-->
<!--              <p class="mb-0 text-white">{{site.offline_nodes}} Device{{site.offline_nodes > 1 ? 's' : ''}} offline</p>-->
<!--              <p class="mb-0 text-white font-14" v-if="site.offline_since !== null">Faulty since <Time :datetime="site.offline_since" format="dd/MM/yyyy HH:mm"></Time></p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="siteBox" v-for="site in filteredSites" :key="site.id" :style="`width: ${widthHeight}vw; height: calc(${widthHeight}vw + 27px); overflow: hidden;`">
        <div style="display: inline-block; width: 100%; height: 100%;">
          <h2 style="font-size: 1.3rem; text-align: center; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%; margin-bottom: 0;">{{site.name}}</h2>
          <monitor-faulty-site :site="site"></monitor-faulty-site>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MonitorFaultySite from "./MonitorFaultySite";
export default {
  name: "MonitorFaultySites",
  components: {MonitorFaultySite},
  props: {
    config: Object
  },
  data(){
    return {
      sites: [],
      interval: null
    }
  },
  mounted(){
    this.loadSites();
    this.interval = setInterval(this.loadSites, 5000);
    setTimeout(() => {
      this.$router.go(0);
    }, 1000 * 60 * 60 * 2);
  },
  beforeUnmount(){
    if(this.interval !== null){
      clearInterval(this.interval);
    }
  },
  methods: {
    loadSites(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/monitoring/faultysites`)
      .then(response => {
        this.sites = response.data.sites;
      })
      .catch(error => {
        console.error(error);
      });
    }
  },
  computed: {
    fontSize: function(){
      if(this.config.fontSize !== null && this.config.fontSize !== undefined){
        return this.config.fontSize + 'px';
      }
      return 24 + 'px';
    },
    fontWeight: function(){
      if(this.config.fontWeight !== null && this.config.fontWeight !== undefined){
        return this.config.fontWeight;
      }
      return 'bold';
    },
    styleObject: function(){
      return {
        fontSize: this.fontSize,
        fontWeight: this.fontWeight,
        position: 'absolute',
        left: this.config.x + 'px',
        top: this.config.y + 'px',
        width: this.config.width + 'px',
        height: this.config.height + 'px',
        padding: '10px',
        textAlign: 'center'
      }
    },
    colSize: function(){
      if(this.config.colSize){
        if(this.config.colSize > 0 && this.config.colSize < 13){
          return this.config.colSize
        }
      }
      return 6;
    },
    widthHeight: function(){
      let wh = 10;
      let c = this.filteredSites.length;

      if (c <= 4) // 2 cols
      {
        wh = 48;
      }
      else if (c <= 12) // 3 cols
      {
        wh = 32;
      }
      else if (c <= 20) // 4 cols
      {
        wh = 24;
      }
      else if (c <= 30) // 5 cols
      {
        wh = 19;
      }
      else if (c <= 48) // 6 cols
      {
        wh = 16;
      }
      else if (c <= 70) // 7 cols
      {
        wh = 13;
      }
      return wh;
    },
    filteredSites: function(){
      return this.sites.filter((s) => {
        return (s.all_nodes > s.offline_nodes);
      });
    }
  }
}
</script>

<style scoped>
  .siteBox {
    margin: 0 auto;
  }
</style>