<template>
  <div id="monitorContainer" ref="monitorContainer" style="height: 100vh; width: 100vw; overflow: hidden;">
    <div class="staging" v-if="!authenticated">
      <p>Monitor: {{width}} x {{height}}</p>
      <p>Authenticated: {{authenticated ? 'Yes' : 'No'}}</p>
      <p>Socket Connected: {{live ? 'Yes' : 'No'}}</p>
      <p>Staging Key: {{staging_key}}</p>
    </div>
    <div ref="widgetContainer" v-else>
      <component v-for="widget in widgets" :socket="socket" :key="'widget-' + widget.id" :is="widget.type" :config="widget.props"></component>
    </div>
  </div>
</template>

<script>
import {io} from "socket.io-client";
import axios from 'axios';
import MonitorTextWidget from "./MonitorTextWidget";
import MonitorSimpleImage from "./MonitorSimpleImage";
import MonitorFaultySites from "./MonitorFaultySites";
import MonitorPingLog from "./MonitorPingLog";
import MonitorStatsWidget from "./MonitorStatsWidget";
import MonitorCompletedJobsWidget from "./MonitorCompletedJobsWidget";
import MonitorMapWidget from "./MonitorMapWidget";

export default {
  name: "Monitor",
  // eslint-disable-next-line vue/no-unused-components
  components: {MonitorTextWidget, MonitorSimpleImage, MonitorFaultySites, MonitorPingLog, MonitorStatsWidget, MonitorCompletedJobsWidget, MonitorMapWidget},
  data(){
    return {
      width: 0,
      height: 0,
      authenticated: false,
      socket: null,
      live: false,
      staging_key: "",
      api_key: null,
      board_id: null,
      token: null,
      widgets: []
    }
  },
  mounted() {

    window.addEventListener('resize', this.getDimensions);
    this.getDimensions();
    this.checkAuthentication();
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  methods: {
    getDimensions(){
      this.width = this.$refs.monitorContainer.clientWidth;
      this.height = this.$refs.monitorContainer.clientHeight;

      if(this.authenticated){
        axios.patch(`${process.env.VUE_APP_API_URL}/v1/monitoring/boards/self/${this.board_id}`, {
          width: this.width,
          height: this.height
        })
        .then(response => {
          if(response.data.success){
            console.log("Updated dimensions");
          }
        })
        .catch(error => {
          console.error("Failed to update dimensions", error);
        })
      }
    },
    checkAuthentication(){
      let monitorToken = localStorage.getItem('link::monitoring::key');
      if(monitorToken !== null){
        this.api_key = monitorToken;
        this.board_id = localStorage.getItem('link::monitoring::boardid');
        this.login();
      }else{
        this.connectSocket();
      }
    },
    checkStaging(){
      if(this.authenticated){
        this.socket.emit('awaiting-authentication', {id: Buffer.from(this.api_key + ':' + this.board_id).toString('base64')});
      }else{
        this.staging_key = this.makeid(6);
        if(this.socket.connected){
          this.socket.emit('awaiting-authentication', {id: this.staging_key});
        }
      }
    },
    makeid(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
      }
      return result;
    },
    connectSocket(){
      this.socket = io('https://adssocket.azurewebsites.net', {
        'reconnection': true,
        'transports': ['websocket'],
        'path': '/socket'
      });

      this.socket.on('connect', () => {
        console.log("Socket connected");
        this.live = true;
        if(this.token){
          this.socket.emit('authentication', {target: 'user', token: this.token});
        }else {
          this.socket.emit("authentication", {target: "staging"});
        }
        console.log("Sent authentication");
      });

      this.socket.on('authenticated', () => {
        console.log("Authenticated");
        if(!this.token){
          this.checkStaging();
        }
      });

      this.socket.on('disconnect', () => {
        this.live = false;
        console.log("Socket disconnected");
      })

      this.socket.on('reconnect', () => {
        if(this.token){
          this.socket.emit('authentication', {target: 'user', token: this.token});
        }else {
          this.socket.emit("authentication", {target: "staging"});
        }
      });

      this.socket.on('provision-board', (msg) => {
        this.api_key = msg.key;
        this.board_id = msg.id;
        localStorage.setItem('link::monitoring::key', this.api_key);
        localStorage.setItem('link::monitoring::boardid', this.board_id);
        this.login();
      });

    },
    login(){
      axios.post(`${process.env.VUE_APP_API_URL}/v1/auth/monitorboard`, {
        key: this.api_key,
        id: this.board_id
      })
      .then(response => {
        if(response.data.token){
          this.token = response.data.token;
          axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
          this.authenticated = true;
          console.log(response.data.widgets);
          let widgets = response.data.widgets;
          widgets.forEach((w) => {
            this.widgets.push({
              id: w.id,
              type: w.type,
              props: JSON.parse(w.config)
            });
          })
          let keyParts = this.token.split('.');
          let payload = keyParts[1];
          let payloadData = JSON.parse(Buffer.from(payload, 'base64').toString());
          let now = Date.now() / 1000;
          let diff = Math.floor(payloadData.exp - now);
          this.tempCounter = diff;
          this.connectSocket();
          setTimeout(this.login, (diff - 60) * 1000);
          this.getDimensions();
        }
      })
      .catch(error => {
        this.$error("Failed to login!", error);
        localStorage.removeItem('link::monitoring::key');
        localStorage.removeItem('link::monitoring::boardid');
        this.$router.go();
      })
    }
  }
}
</script>

<style scoped>
  .staging {
    font-size: 48px;
    padding: 20px;
    color: white;
  }
  #monitorContainer {
    display: block;
    background-color: black;
    background-image: url('/assets/images/adwp.jpg');
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain; /* Resize the background image to cover the entire container */
  }
  selector { cursor: none; }
</style>