<template>
  <div :style="styleObject">
    <div class="row">
      <div class="col-6">
        <div class="card">
          <div class="card-body text-white">
            <p class="statTitle">Cameras</p>
            <p class="statValue">{{cameras.toLocaleString()}}</p>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-body text-white">
            <p class="statTitle">Online Sites</p>
            <p class="statValue">{{sites.toLocaleString()}}</p>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-body text-white">
            <p class="statTitle">Open Jobs</p>
            <p class="statValue">{{jobs.toLocaleString()}}</p>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-body text-white">
            <p class="statTitle">Sites Under Refurb</p>
            <p class="statValue">{{underRefurb.toLocaleString()}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from "sweetalert2";
export default {
  name: "MonitorStatsWidget",
  props: {
    config: Object,
    socket: Object
  },
  data(){
    return {
      cameras: 0,
      sites: 0,
      jobs: 0,
      underRefurb: 0
    }
  },
  mounted(){
    setInterval(this.loadStats, 5000);
    this.loadStats();
  },
  methods: {
    loadStats(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/monitoring/stats`)
      .then(response => {
        this.cameras = response.data.cameras;
        this.sites = response.data.sites;
        this.jobs = response.data.jobs;
        this.underRefurb = response.data.underRefurb;
      })
      .catch(error => {
        this.$error("Failed to load stats", error);
      })
    },
    handleNewSite(){
      this.sites++;
    },
    handleNewDevices(msg){
      this.cameras += msg.count;
    },
    handleNewJobs(){
      this.jobs++;
    },
    handleCompletedJobs(){
      this.stats.completedJobs++;
      this.stats.openJobs--;
      this.loadRecentJobs();
      let timerInterval
      Swal.fire({
        icon: 'success',
        title: 'Job Completed!',
        html: 'A job has just been completed!',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      });
    },
    handleDeleteJobs(msg){
      if(!msg.completed){
        this.jobs--;
      }
    }
  },
  computed: {
    fontSize: function(){
      if(this.config.fontSize !== null && this.config.fontSize !== undefined){
        return this.config.fontSize + 'px';
      }
      return 24 + 'px';
    },
    fontWeight: function(){
      if(this.config.fontWeight !== null && this.config.fontWeight !== undefined){
        return this.config.fontWeight;
      }
      return 'bold';
    },
    styleObject: function(){
      return {
        fontSize: this.fontSize,
        fontWeight: this.fontWeight,
        position: 'absolute',
        left: this.config.x + 'px',
        top: this.config.y + 'px',
        width: this.config.width + 'px',
        height: this.config.height + 'px',
        padding: '10px'
      }
    }
  }
}
</script>

<style scoped>
  .card {
    text-align: center;
  }
  .statTitle {
    font-size: 36px;
    font-weight: bold;
  }
  .statValue {
    font-size: 96px;
  }
</style>