<template>
  <div :style="styleObject" class="card bg-dark" style="overflow-y: hidden; overflow-x: hidden;">
    <table class="table">
      <tbody>
        <tr v-for="log in logs" :key="'log-' + log.ts">
          <td>{{log.site}}</td>
          <td>{{log.type}}</td>
          <td>{{log.product}}</td>
          <td><Time :datetime="log.ts" format="dd/MM/yyyy HH:mm:ss"></Time></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {DateTime} from 'luxon';
import Time from "../../components/Time";
export default {
  name: "MonitorPingLog",
  components: {Time},
  props: {
    config: Object,
    socket: Object
  },
  data(){
    return {
      logs: []
    }
  },
  mounted(){
    this.socket.on('ping-log', this.newLog);

    if(this.socket.authenticated){
      this.socket.emit('join-channel', {channel: 'ping-log', user_id: 'MONITOR_BOARD'});
    }else{
      setTimeout(() => {
        this.socket.emit('join-channel', {channel: 'ping-log', user_id: 'MONITOR_BOARD'});
      }, 5000);
    }
  },
  beforeUnmount(){
    this.socket.off('ping-log', this.newLog);
  },
  methods: {
    newLog(msg){
      msg.ts = DateTime.local().toSeconds();
      this.logs.unshift(msg);
      if(this.config.max !== null && this.config.max !== undefined){
        if(this.logs.length > this.config.max){
          this.logs.pop();
        }
      }else{
        if(this.logs.length > 15){
          this.logs.pop();
        }
      }
    }
  },
  computed: {
    filteredLogs: function(){
      if(this.config.max !== null && this.config.max !== undefined){
        return this.logs.slice(0, this.config.max);
      }
      return this.logs.slice(0, 15);
    },
    fontSize: function(){
      if(this.config.fontSize !== null && this.config.fontSize !== undefined){
        return this.config.fontSize + 'px';
      }
      return 18 + 'px';
    },
    fontWeight: function(){
      if(this.config.fontWeight !== null && this.config.fontWeight !== undefined){
        return this.config.fontWeight;
      }
      return 'bold';
    },
    styleObject: function(){
      return {
        fontSize: this.fontSize,
        fontWeight: this.fontWeight,
        position: 'absolute',
        left: this.config.x + 'px',
        top: this.config.y + 'px',
        width: this.config.width + 'px',
        height: this.config.height + 'px',
        padding: '10px',
        textAlign: 'center'
      }
    },
  }
}
</script>

<style scoped>

</style>