<template>
  <canvas ref="canvas" style="display: block; width: 65%; height: 65%; margin: 0 auto; margin-top: -20px;"></canvas>
</template>

<script>
/* global Chart */
export default {
  name: "MonitorFaultySite",
  props: [
      "site"
  ],
  data(){
    return {
      chart: null,
    }
  },
  mounted(){
    let container = this.$refs.canvas;
    let ctx = container.getContext('2d');

    Chart.defaults.global.legend.display = false;

    this.chart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Online', 'Offline'],
        datasets: [
          {
            data: [this.site.all_nodes - this.site.offline_nodes, this.site.offline_nodes],
            backgroundColor: ['#29ca42', 'red']
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        }
      },
    });
  }
}
</script>

<style scoped>

</style>