<template>
  <div id="map" :style="styleObject"></div>
</template>

<script>
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {Point} from "ol/geom";
import {Feature} from "ol";
import {fromLonLat} from "ol/proj";
import {Stroke, Style, Fill, Text} from "ol/style";
import CircleStyle from "ol/style/Circle";
import {FullScreen, defaults as defaultControls} from 'ol/control';
import axios from 'axios';
import {DateTime} from "luxon";
// import Point from 'ol/geom/Point';
// import Feature from 'ol/Feature';
// import * as Proj from 'ol/proj';
// import VectorLayer from 'ol/layer/Vector';
// import VectorSource from 'ol/source/Vector';
// import {Icon, Style} from 'ol/style';
export default {
  name: 'MonitorMapWidget',
  props: {
    config: Object,
    socket: Object
  },
  data(){
    return {
      x: 383018,
      y: 5967425,
      map: null,
      positions: [],
      layer: null,
      vectorSource: null,
      markersLayer: null,
      vehicles: []
    };
  },
  mounted() {

    this.socket.on('tracking', (msg) => {

      let posExists = this.positions.filter((pos) => {
        return pos.imei == msg.imei;
      });

      if(posExists.length > 0){
        posExists[0].longitude = msg.gps.longitude;
        posExists[0].latitude = msg.gps.latitude;
        let point2 = new Point(
            fromLonLat([msg.gps.longitude, msg.gps.latitude])
        );
        point2.rotate((msg.gps.angle * Math.PI / 180), fromLonLat([msg.gps.longitude, msg.gps.latitude]))
        posExists[0].marker.setGeometry(point2);

        let vehicle = this.vehicles.filter((v) => {
          return v.tracker_imei == msg.imei;
        })[0];

        this.$emit('updateSpeed', {vehicle: vehicle, speed: `${parseFloat(msg.gps.speed / 1.6034).toFixed(0)}`});

        // console.log(vehicle);

        let color = 'black';
        // console.log("Engineer: ", vehicle.type);
        let radius = 8;
        switch (vehicle.type) {
          case 'Engineer':
            color = 'green';
            break;
          case 'Office':
            color = 'orange';
            break;
          case 'Personal':
            color = 'red';
            break;
          case 'Job':
            color = 'black';
            break;
        }

        let style = new Style({
          image: new CircleStyle({
            radius: radius,
            fill: new Fill({color: color}),
            stroke: new Stroke({
              color: 'white', width: 2
            })
          }),
          text: new Text({
            text: `${vehicle.user?.fname || ""} ${vehicle.user?.lname || "Unassigned"}\n${vehicle.registration}\n${parseFloat(msg.gps.speed / 1.6034).toFixed(0)} mph`,
            scale: 1.3,
            fill: new Fill({
              color: '#fff'
            }),
            stroke: new Stroke({
              color: '0',
              width: 3
            }),
            offsetY: 20
          })
        });

        if(!posExists.hidden){
          posExists[0].marker.setStyle(style);
        }

        posExists[0].lastStyle = style;

      }else{
        this.addMarker(msg.gps.latitude, msg.gps.longitude, msg.imei);
      }
    })

    //-2.1701494728097317
    //54.283022901894064, -1.935859693073496
    //54.17846504171028, -1.9254899804157477
    let centerPoint = new Point(
        fromLonLat( [-1.946229405731244,54.183022901894064])
    )
    this.map = new Map({
      target: 'map',
      controls: defaultControls().extend([new FullScreen()]),
      layers: [
        new TileLayer({
          source: new XYZ({
            url: "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga"
          })
        })
      ],
      view: new View({
        center: centerPoint.getCoordinates(),
        zoom: 7.6
      })
    });

    this.vectorSource = new VectorSource({});

    this.markersLayer = new VectorLayer({
      source: this.vectorSource,
    });

    this.map.addLayer(this.markersLayer);

    axios.get(`${process.env.VUE_APP_API_URL}/v1/map`)
    .then(response => {
      this.vehicles = response.data.vehicles;
      this.token = response.data.token;
      this.socket.emit('join-tracking', {token: this.token});
      this.show = true;
      // console.log(this.vehicles.length);
      this.vehicles.forEach((vehicle) => {
        if(vehicle.lastLocation.gps){
          console.log("Adding vehicle", vehicle.registration)
          this.addMarker(vehicle.lastLocation.gps.latitude, vehicle.lastLocation.gps.longitude, vehicle.tracker_imei, `${vehicle.user?.fname || ""} ${vehicle.user?.lname || "Unassigned"}\n${vehicle.registration}\n${parseFloat(vehicle.lastLocation.gps.speed / 1.6034).toFixed(0)} mph`, vehicle.lastLocation.gps.angle, vehicle.type);
        }
      })
    })
    .catch(error => {
      this.$error("Failed to load vehicles", error);
    })

    this.loadJobMarkers();
    setInterval(this.loadJobMarkers, 30000);

  },
  beforeUnmount(){
    if(this.socket !== null){
      this.socket.close();
      console.log("Closed socket!");

    }
  },
  methods: {
    loadJobMarkers() {
      axios.get(`${process.env.VUE_APP_API_URL}/v1/map/jobs`)
          .then(response => {

            this.positions.forEach((p) => {
              if(p.imei.includes("JOB_MARKER_")){
                this.vectorSource.removeFeature(p.marker);
              }
            })

            this.positions = this.positions.filter((p) => {
              return !p.imei.includes("JOB_MARKER_");
            });

            response.data.markers.forEach((m) => {
              this.addMarker(m.latitude, m.longitude, 'JOB_MARKER_' + m.id, `JR #${m.id}\n${m.name}`, 0, 'Job')
            })

          })
          .catch(error => {
            this.$error("Failed to load job markers", error);
          })
    },
    addMarker(lat, long, imei, label = "", rotation, type) {
      let point1 = new Point(
          fromLonLat([long, lat])
      );
      point1.rotate((rotation * Math.PI / 180), fromLonLat([long, lat]))
      let newMarker = new Feature({
        geometry: point1,
        name: imei
      })

      let color = 'black';
      let radius = 8;
      switch (type) {
        case 'Engineer':
          color = 'green';
          break;
        case 'Office':
          color = 'orange';
          break;
        case 'Personal':
          color = 'red';
          break;
        case 'Job':
          color = 'black';
          radius = 7;
          label = "";
          break;
      }

      let style = new Style({
        image: new CircleStyle({
          radius: radius,
          fill: new Fill({color: color}),
          stroke: new Stroke({
            color: 'white', width: 2
          })
        }),
        text: new Text({
          text: label,
          scale: 1.3,
          fill: new Fill({
            color: '#fff'
          }),
          stroke: new Stroke({
            color: '0',
            width: 3
          }),
          offsetY: 20
        })
      });

      newMarker.setStyle(style);

      this.vectorSource.addFeature(newMarker);

      this.positions.push({
        imei: imei,
        longitude: long,
        latitude: lat,
        marker: newMarker,
        type: type,
        lastStyle: style,
        hidden: false
      })
    },
    hideMarker(vehicle) {
      let pos = this.positions.filter((p) => {
        return p.imei == vehicle.tracker_imei;
      })[0];
      pos.setStyle(null);
      pos.hidden = true;
    },
    showMarker(vehicle) {
      let pos = this.positions.filter((p) => {
        return p.imei == vehicle.tracker_imei;
      })[0];
      pos.setStyle(pos.lastStyle);
      pos.hidden = false;
    }
  },
  computed: {
    fontSize: function(){
      if(this.config.fontSize !== null && this.config.fontSize !== undefined){
        return this.config.fontSize + 'px';
      }
      return 24 + 'px';
    },
    fontWeight: function(){
      if(this.config.fontWeight !== null && this.config.fontWeight !== undefined){
        return this.config.fontWeight;
      }
      return 'bold';
    },
    styleObject: function(){
      return {
        fontSize: this.fontSize,
        fontWeight: this.fontWeight,
        position: 'absolute',
        left: this.config.x + 'px',
        top: this.config.y + 'px',
        width: this.config.width + 'px',
        height: this.config.height + 'px',
        padding: '10px'
      }
    },
    now: function(){
      return DateTime.local().toSeconds();
    }
  }
}
</script>