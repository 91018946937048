<template>
  <span>{{formatted}}</span>
</template>

<script>
import {DateTime} from 'luxon';
export default {
  name: 'Time',
  props: ['datetime', 'format'],
  computed: {
    formatted: function(){
      return DateTime.fromSeconds(this.datetime, {zone: 'UTC'}).toLocal().toFormat(this.format);
    }
  }
}
</script>